@font-face {
  font-family: MontseBold;
  src: url(../fonts/Montserrat-Bold.ttf);
  font-display: fallback; }

@font-face {
  font-family: MontseRegular;
  src: url(../fonts/Montserrat-Regular.ttf);
  font-display: fallback; }

.header-container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em 1.2em;
  z-index: 99; }
  .header-container .logo--color {
    display: none; }
  @media (min-width: 1200px) {
    .header-container {
      width: 85%;
      left: 50%;
      transform: translateX(-50%);
      padding: 0.8em 0;
      transition: all ease 0.3s; } }

.header-container.fixed {
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 0 2em;
  left: 0;
  transform: none;
  background-color: white;
  color: black;
  height: 50px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3); }
  .header-container.fixed .logo--white {
    display: none; }
  .header-container.fixed .logo--color {
    display: block;
    height: 40px; }
    @media (min-width: 1200px) {
      .header-container.fixed .logo--color {
        width: 110px;
        height: auto;
        margin-left: 40px; } }
  @media (min-width: 1200px) {
    .header-container.fixed {
      height: 60px; } }
  .header-container.fixed a {
    color: black;
    transition: color 0.3s ease; }
    .header-container.fixed a:hover {
      color: #67131c; }
  .header-container.fixed .hamburger {
    background-color: #4f4f4f; }
    .header-container.fixed .hamburger::before, .header-container.fixed .hamburger::after {
      background-color: #4f4f4f; }

.logo img {
  height: 60px; }
  @media (min-width: 1200px) {
    .logo img {
      height: auto;
      width: 170px; } }

.menu--btn {
  padding: 1.2em 0.6em; }
  @media (min-width: 1200px) {
    .menu--btn {
      display: none; } }

.hamburger {
  width: 30px;
  position: relative;
  display: block;
  margin: 0 auto;
  height: 3px;
  background-color: white; }
  .hamburger::before {
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    background-color: white;
    transform: translateY(-6px); }
  .hamburger::after {
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    background-color: white;
    transform: translateY(3px); }

.nav-desktop {
  display: none;
  color: white;
  font-family: MontseRegular; }
  @media (min-width: 1200px) {
    .nav-desktop {
      display: flex;
      justify-content: space-between;
      width: 70%; } }
  .nav-desktop .nav-menu {
    width: 170%; }
    .nav-desktop .nav-menu ul {
      justify-content: space-around; }
      .nav-desktop .nav-menu ul a {
        cursor: pointer; }
    .nav-desktop .nav-menu-item {
      display: inline-block;
      padding: 0.4em 0.8em;
      color: white;
      text-decoration: none; }
  .nav-desktop .nav-dummy {
    width: 45%; }
  .nav-desktop .nav-socials {
    width: 40%; }
    .nav-desktop .nav-socials ul {
      justify-content: space-around; }
    .nav-desktop .nav-socials-link {
      display: inline-block;
      text-decoration: none;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: #4f4f4f;
      border: solid 1px white;
      border-radius: 50%;
      font-size: 18px; }
  .nav-desktop .nav-menu ul, .nav-desktop .nav-socials ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex; }

@font-face {
  font-family: MontseBold;
  src: url(../fonts/Montserrat-Bold.ttf);
  font-display: fallback; }

@font-face {
  font-family: MontseRegular;
  src: url(../fonts/Montserrat-Regular.ttf);
  font-display: fallback; }

.menu__overlay {
  width: 0;
  height: 100%;
  background-color: #f5f5f5;
  position: fixed;
  z-index: 1001;
  top: 0;
  right: -100%;
  transition: right 0.5s ease, width 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center; }

.menu__overlay.open {
  width: 100%;
  right: 0; }

.menu__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.close--menu {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  font-size: 40px;
  font-weight: bold;
  color: #67131c;
  padding: 0.1em 0.4em; }

.menu {
  text-align: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }
  .menu__item--link {
    font-size: 25px;
    font-family: MontseBold;
    color: #67131c;
    text-decoration: none;
    text-transform: uppercase; }

.menu-socials {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  font-size: 25px;
  padding: 1em; }
  .menu-socials a {
    color: #67131c; }

.menu-socials--items + .menu-socials--items {
  margin-left: 25px; }

@font-face {
  font-family: MontseBold;
  src: url(../fonts/Montserrat-Bold.ttf);
  font-display: fallback; }

@font-face {
  font-family: MontseRegular;
  src: url(../fonts/Montserrat-Regular.ttf);
  font-display: fallback; }

* {
  box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  padding: 0; }

body.stop--scrolling {
  height: 100vh;
  overflow: hidden; }

h1, h2, h3, p {
  margin: 0;
  padding: 0; }

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit; }

.default--btn {
  display: inline-block;
  border: none;
  outline: none;
  background-color: lightgray;
  font-family: MontseRegular;
  color: #67131c;
  padding: 0.8em 1.2em;
  text-decoration: none;
  font-size: 15px;
  transition: color 0.3s ease, background-color 0.3s ease; }
  .default--btn:hover {
    color: lightgray;
    background-color: #67131c; }

.alternative--btn {
  display: inline-block;
  border: none;
  outline: none;
  background-color: #67131c;
  font-family: MontseRegular;
  color: lightgray;
  padding: 0.8em 1.2em;
  text-decoration: none;
  font-size: 15px;
  transition: color 0.3s ease, background-color 0.3s ease; }
  .alternative--btn:hover {
    color: #67131c;
    background-color: lightgray; }

.no-webp .first-fold {
  background: url("../images/firstfold.png") center; }

.webp .first-fold {
  background: url("../images/firstfold.webp") center; }

.first-fold {
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: -1; }
  .first-fold__container {
    max-width: 80%;
    margin: 0 auto; }
  .first-fold h1 {
    font-family: MontseBold;
    font-size: 23px;
    line-height: 140%; }
    @media (min-width: 760px) {
      .first-fold h1 {
        font-size: 30px; } }
    @media (min-width: 1400px) {
      .first-fold h1 {
        font-size: 55px; } }

.about {
  background-color: #f5f5f5;
  height: 600px;
  position: relative; }
  @media (min-width: 1200px) {
    .about {
      height: 100vh; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .about {
      height: 600px; } }
  .about__container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%; }
    .about__container .carousel__wrapper {
      display: block;
      background-color: white;
      padding: 3em 2.5em;
      width: 350px;
      height: 450px;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
      .about__container .carousel__wrapper .carousel__redline {
        position: absolute;
        display: block;
        height: 1px;
        width: 150px;
        background-color: #67131c;
        margin-top: 100px;
        margin-left: -2.5em; }
        @media (min-width: 1200px) {
          .about__container .carousel__wrapper .carousel__redline {
            margin-left: -4em; } }
        @media (min-width: 1400px) {
          .about__container .carousel__wrapper .carousel__redline {
            margin-left: -4.5em;
            margin-top: 110px; } }
        @media (min-width: 1650px) {
          .about__container .carousel__wrapper .carousel__redline {
            margin-left: -5em;
            width: 250px;
            margin-top: 240px; } }
      .about__container .carousel__wrapper .dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 3em;
        text-align: center; }
        @media (min-width: 1200px) {
          .about__container .carousel__wrapper .dots {
            bottom: 4em; } }
        .about__container .carousel__wrapper .dots .dot {
          display: inline-block;
          position: relative;
          border-radius: 50%;
          height: 8px;
          width: 8px;
          background-color: #67131c;
          cursor: pointer; }
        .about__container .carousel__wrapper .dots .dot + .dot {
          margin-left: 20px; }
      @media (min-width: 760px) {
        .about__container .carousel__wrapper {
          width: 500px;
          left: 80px; } }
      @media (min-width: 1200px) {
        .about__container .carousel__wrapper {
          width: 500px;
          left: 150px;
          display: flex;
          flex-direction: column;
          padding: 4em; } }
      @media (min-width: 1400px) {
        .about__container .carousel__wrapper {
          padding: 4.5em;
          width: 600px;
          height: 550px; } }
      @media (min-width: 1650px) {
        .about__container .carousel__wrapper {
          width: 750px;
          left: 200px;
          padding: 5em;
          height: 650px; } }
  .about--title {
    color: #67131c; }
  .about--txt {
    color: #4f4f4f; }
  .about--white-space {
    display: block;
    width: 50%; }
  .about--foto {
    width: 100%;
    overflow: hidden;
    position: relative; }
    .about--foto img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      transition: opacity 0.8s ease-in-out;
      opacity: 0;
      filter: alpha(opacity=0);
      height: 100%; }
      @media (min-width: 1020px) {
        .about--foto img {
          height: auto;
          width: 100%; } }

.about--h2 {
  opacity: 0;
  transform: translateY(-50px);
  transition: transform 0.5s linear, opacity 0.4s linear;
  filter: alpha(opacity=0);
  font-family: MontseBold;
  font-size: 23px;
  position: absolute;
  width: 100%; }
  @media (min-width: 760px) {
    .about--h2 {
      font-size: 30px; } }
  @media (min-width: 1650px) {
    .about--h2 {
      font-size: 55px; } }

.about--h2.active {
  opacity: 1;
  transform: translateY(0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=1); }

.about--p {
  opacity: 0;
  transform: translateY(-50px);
  transition: transform 0.5s linear, opacity 0.4s linear;
  font-family: MontseRegular;
  position: absolute;
  margin-top: 120px;
  width: 100%; }
  @media (min-width: 760px) {
    .about--p {
      margin-top: 130px; } }
  @media (min-width: 1200px) {
    .about--p {
      margin-top: 120px;
      font-size: 16px;
      line-height: 150%; } }
  @media (min-width: 1400px) {
    .about--p {
      margin-top: 140px; } }
  @media (min-width: 1650px) {
    .about--p {
      margin-top: 275px; } }

.about--p.active {
  opacity: 1;
  transform: translateY(0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=1); }

.about--img.opaque {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=1); }

.arrow--btn {
  display: none;
  position: absolute; }
  .arrow--btn img {
    width: 35px; }
  @media (min-width: 1200px) {
    .arrow--btn {
      display: block;
      bottom: 3.3em; } }

.arrow--btn.left {
  left: 2.5em;
  transform: rotate(180deg); }

.arrow--btn.right {
  right: 2.5em; }

/* 
.no-webp .about--foto1 {
    background: url('../images/about.png') center;
    background-size: cover;
}

.webp .about--foto1{
    background: url("../images/about.webp") center;
    background-size: cover;
}

.no-webp .about--foto2 {
    background: url('../images/about2.png') center;
    background-size: cover;
}

.webp .about--foto2 {
    background: url("../images/about2.webp") center;
    background-size: cover;
}

.no-webp .about--foto3 {
    background: url('../images/about3.png') center;
    background-size: cover;
}

.webp .about--foto3{
    background: url("../images/about3.webp") center;
    background-size: cover;
}
 */
.dot.active::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: solid 1px #67131c;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: grow 0.5s forwards; }

.dot:hover::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: solid 1px #67131c;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: grow 0.5s forwards; }

@keyframes grow {
  from {
    width: 10px;
    height: 10px; }
  to {
    height: 20px;
    width: 20px; } }

.no-webp .servicio--one {
  background: url(../images/diagnosticos.png) center; }

.webp .servicio--one {
  background: url(../images/diagnosticos.webp) center; }

.no-webp .servicio--two {
  background: url(../images/ingenieria_termica.png) center; }

.webp .servicio--two {
  background: url(../images/ingenieria_termica.webp) center; }

.no-webp .servicio--three {
  background: url(../images/llave_en_mano.png) center; }

.webp .servicio--three {
  background: url(../images/llave_en_mano.webp) center; }

.no-webp .servicio--four {
  background: url(../images/fabricacion_tanques.png) center; }

.webp .servicio--four {
  background: url(../images/fabricacion_tanques.webp) center; }

.no-webp .servicio--five {
  background: url(../images/capacitaciones.png) center; }

.webp .servicio--five {
  background: url(../images/capacitaciones.webp) center; }

.servicios {
  width: 100%;
  position: relative;
  overflow: hidden; }
  @media (min-width: 1200px) {
    .servicios {
      height: 100vh;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto auto; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .servicios {
      height: auto; } }
  .servicios .servicio--one {
    background-size: cover; }
    @media (min-width: 1200px) {
      .servicios .servicio--one {
        grid-column: 1/2;
        grid-row: 1; } }
  .servicios .servicio--two {
    background-size: cover; }
    @media (min-width: 1200px) {
      .servicios .servicio--two {
        grid-column: 2/3;
        grid-row: 1; } }
  .servicios .servicio--three {
    background-size: cover; }
    @media (min-width: 1200px) {
      .servicios .servicio--three {
        grid-column: 1/2;
        grid-row: 2; } }
  .servicios .servicio--four {
    background-size: cover; }
  .servicios .servicio--five {
    background-size: cover; }
  .servicios .servicio {
    width: 100%;
    min-height: 350px;
    padding: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white; }
    @media (min-width: 1200px) {
      .servicios .servicio {
        font-size: 40px;
        min-height: 0;
        height: 50vh; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      .servicios .servicio {
        min-height: 350px;
        height: auto; } }
    .servicios .servicio__title {
      font-family: MontseBold;
      font-size: 23px; }
    .servicios .servicio__desc {
      font-family: MontseRegular;
      display: block;
      height: 0;
      opacity: 0;
      font-size: 15px;
      margin: 1em 0;
      transition: height 0.4s, opacity 0.5s; }
    .servicios .servicio .btn-servicio {
      cursor: pointer;
      width: 45px;
      height: 45px;
      z-index: 50; }
      .servicios .servicio .btn-servicio img {
        width: 100%; }
    .servicios .servicio .servicio-one-desc.open, .servicios .servicio .servicio-two-desc.open, .servicios .servicio .servicio-three-desc.open, .servicios .servicio .servicio-four-desc.open, .servicios .servicio .servicio-five-desc.open {
      height: auto;
      opacity: 1; }
  @media (min-width: 760px) {
    .servicios .servicio__split {
      display: flex; } }
  @media (min-width: 1200px) {
    .servicios .servicio__split {
      grid-column: 2/3;
      grid-row: 2; } }

.sectores {
  width: 100%;
  background-color: #f5f5f5;
  text-align: center;
  padding: 3em 1.5em;
  position: relative; }
  @media (min-width: 1200px) {
    .sectores {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .sectores {
      height: auto;
      padding: 4em 1.5em; } }
  @media (min-width: 1650px) {
    .sectores {
      display: block;
      padding: 5em; } }
  .sectores__title {
    font-family: MontseBold;
    color: #67131c;
    font-size: 23px; }
    @media (min-width: 760px) {
      .sectores__title {
        font-size: 30px;
        text-align: center; } }
    @media (min-width: 1650px) {
      .sectores__title {
        font-size: 55px; } }
  .sectores__txt {
    font-family: MontseRegular;
    color: #4f4f4f;
    margin: 0.8em 0 1.5em 0; }
    @media (min-width: 1200px) {
      .sectores__txt {
        font-size: 16px;
        text-align: center;
        margin: 0;
        margin-top: 10px; } }
  @media (min-width: 760px) {
    .sectores__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      gap: 30px;
      margin: 0 auto;
      margin-top: 50px;
      width: 85%; } }
  @media (min-width: 1650px) {
    .sectores__grid {
      margin-top: 100px; } }
  @media (min-width: 1650px) {
    .sectores__grid {
      margin-top: 100px;
      gap: 100px; } }
  .sectores__grid .sector {
    margin: 3em 0; }
    @media (min-width: 760px) {
      .sectores__grid .sector {
        text-align: left;
        margin-left: 30px; } }
    @media (min-width: 1200px) {
      .sectores__grid .sector {
        margin: 0;
        margin-left: 30px;
        text-align: left; } }
    @media (min-width: 1650px) {
      .sectores__grid .sector {
        margin-left: 80px; } }
    .sectores__grid .sector__icon {
      max-width: 90px; }
    .sectores__grid .sector__title {
      margin: 0.8em 0;
      font-family: MontseBold;
      color: #4f4f4f; }
      @media (min-width: 1200px) {
        .sectores__grid .sector__title {
          font-size: 20px;
          margin: 0.4em 0; } }
    .sectores__grid .sector__list {
      font-family: MontseRegular;
      color: #4f4f4f; }
  .sectores__grid .sector ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  .sectores__grid .sector ul li {
    margin: 0;
    padding: 0; }
    @media (min-width: 1200px) {
      .sectores__grid .sector ul li {
        font-size: 16px; } }

.proveedores {
  width: 100%;
  text-align: center;
  padding: 3em 1.5em;
  position: relative;
  background-color: white; }
  @media (min-width: 1200px) {
    .proveedores {
      height: 100vh;
      padding: 3em 0; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .proveedores {
      height: auto; } }
  .proveedores__title {
    font-family: MontseBold;
    color: #67131c;
    font-size: 23px; }
    @media (min-width: 760px) {
      .proveedores__title {
        font-size: 30px; } }
    @media (min-width: 1650px) {
      .proveedores__title {
        font-size: 55px;
        margin-top: 0.8em; } }
  .proveedores__txt {
    font-family: MontseRegular;
    color: #4f4f4f;
    margin: 0.8em 0 1.5em 0;
    font-size: 16px; }
    @media (min-width: 1200px) {
      .proveedores__txt {
        margin: 1em 0 2em 0; } }
  .proveedores__grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 20px; }
    @media (min-width: 760px) {
      .proveedores__grid {
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(15, 1fr);
        grid-template-rows: auto auto auto auto auto;
        align-items: center;
        gap: 10px; } }
    @media (min-width: 1200px) {
      .proveedores__grid {
        width: 80%;
        grid-template-columns: repeat(15, 1fr);
        grid-template-rows: auto auto auto;
        margin-top: 40px; } }
    @media (min-width: 1650px) {
      .proveedores__grid {
        width: 85%;
        gap: 20px;
        margin-top: 80px; } }
    .proveedores__grid .proveedor__logo {
      width: 100px; }
      @media (min-width: 1200px) {
        .proveedores__grid .proveedor__logo {
          width: 120px; } }
      @media (min-width: 1650px) {
        .proveedores__grid .proveedor__logo {
          width: 150px; } }
    @media (min-width: 760px) {
      .proveedores__grid .proveedor {
        margin: 0; }
        .proveedores__grid .proveedor--one {
          grid-column: 1/6;
          grid-row: 1; }
        .proveedores__grid .proveedor--two {
          grid-column: 6/11;
          grid-row: 1; }
        .proveedores__grid .proveedor--three {
          grid-column: 11/16;
          grid-row: 1; }
        .proveedores__grid .proveedor--four {
          grid-column: 1/6;
          grid-row: 2; }
        .proveedores__grid .proveedor--five {
          grid-column: 6/11;
          grid-row: 2; }
        .proveedores__grid .proveedor--six {
          grid-column: 11/16;
          grid-row: 2; }
        .proveedores__grid .proveedor--seven {
          grid-column: 1/6;
          grid-row: 3; }
        .proveedores__grid .proveedor--eight {
          grid-column: 6/11;
          grid-row: 3; }
        .proveedores__grid .proveedor--nine {
          grid-column: 11/16;
          grid-row: 3; }
        .proveedores__grid .proveedor--ten {
          grid-column: 1/6;
          grid-row: 4; }
        .proveedores__grid .proveedor--eleven {
          grid-column: 6/11;
          grid-row: 4; }
        .proveedores__grid .proveedor--twelve {
          grid-column: 11/16;
          grid-row: 4; }
        .proveedores__grid .proveedor--thirteen {
          grid-column: 1/6;
          grid-row: 5; }
        .proveedores__grid .proveedor--fourteen {
          grid-column: 6/11;
          grid-row: 5; }
        .proveedores__grid .proveedor--fifthteen {
          grid-column: 11/16;
          grid-row: 5; } }
    @media (min-width: 1200px) {
      .proveedores__grid .proveedor--one {
        grid-column: 1/4;
        grid-row: 1; }
      .proveedores__grid .proveedor--two {
        grid-column: 4/7;
        grid-row: 1; }
      .proveedores__grid .proveedor--three {
        grid-column: 7/10;
        grid-row: 1; }
      .proveedores__grid .proveedor--four {
        grid-column: 10/13;
        grid-row: 1; }
      .proveedores__grid .proveedor--five {
        grid-column: 13/16;
        grid-row: 1; }
      .proveedores__grid .proveedor--six {
        grid-column: 1/4;
        grid-row: 2; }
      .proveedores__grid .proveedor--seven {
        grid-column: 4/7;
        grid-row: 2; }
      .proveedores__grid .proveedor--eight {
        grid-column: 7/10;
        grid-row: 2; }
      .proveedores__grid .proveedor--nine {
        grid-column: 10/13;
        grid-row: 2; }
      .proveedores__grid .proveedor--ten {
        grid-column: 13/16;
        grid-row: 2; }
      .proveedores__grid .proveedor--eleven {
        grid-column: 1/4;
        grid-row: 3; }
      .proveedores__grid .proveedor--twelve {
        grid-column: 4/7;
        grid-row: 3; }
      .proveedores__grid .proveedor--thirteen {
        grid-column: 7/10;
        grid-row: 3; }
      .proveedores__grid .proveedor--fourteen {
        grid-column: 10/13;
        grid-row: 3; }
      .proveedores__grid .proveedor--fifthteen {
        grid-column: 13/16;
        grid-row: 3; } }

.contact {
  width: 100%;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden; }
  @media (min-width: 1200px) {
    .contact {
      height: 100vh;
      position: relative; } }
  .contact__container {
    width: 100%; }
    .contact__container__whitespace {
      display: block;
      background-color: #f5f5f5; }
    @media (min-width: 1200px) {
      .contact__container {
        width: 100%;
        height: 100%;
        display: flex; } }

.no-webp .info {
  background: url(../images/contacto.png) center; }

.webp .info {
  background: url(../images/contacto.webp) center; }

.info {
  background: url(../images/contacto.png) center;
  background-size: cover;
  color: white;
  font-family: MontseRegular;
  padding: 4em 2.3em; }
  @media (min-width: 760px) {
    .info {
      padding: 5em; } }
  @media (min-width: 1200px) {
    .info {
      width: 75%;
      padding: 8em 23em 8em 8em;
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  @media (min-width: 1650px) {
    .info {
      padding: 12em 25em 12em 12em;
      justify-content: space-around; } }
  .info__title {
    font-family: MontseBold;
    font-size: 23px; }
    @media (min-width: 760px) {
      .info__title {
        font-size: 30px; } }
    @media (min-width: 1650px) {
      .info__title {
        font-size: 55px; } }
  .info__txt {
    margin-top: 0.8rem;
    font-size: 14px; }
    @media (min-width: 1200px) {
      .info__txt {
        font-size: 16px; } }
  .info__icon {
    width: 25px; }
  .info__email--btn {
    margin-top: 1.5em;
    width: 160px;
    text-align: center; }
    @media (min-width: 1200px) {
      .info__email--btn {
        margin: 0; } }
  .info__grid {
    margin: 1.2rem 0;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    gap: 15px;
    font-size: 14px; }
    @media (min-width: 1200px) {
      .info__grid {
        margin: 0;
        gap: 30px;
        font-size: 16px; } }
    @media (min-width: 1400px) {
      .info__grid {
        gap: 0;
        row-gap: 30px;
        width: 85%; } }
    .info__grid a {
      color: white;
      font-size: 14px; }
      @media (min-width: 1200px) {
        .info__grid a {
          font-size: 16px; } }
    .info__grid--location-icon {
      grid-row: 1;
      grid-column: 1/2; }
    .info__grid--location-txt {
      grid-row: 1;
      grid-column: 2/3; }
    .info__grid--phone-icon {
      grid-row: 2;
      grid-column: 1/2; }
    .info__grid--phone-txt {
      grid-row: 2;
      grid-column: 2/3; }
    .info__grid--email-icon {
      grid-row: 3;
      grid-column: 1/2; }
    .info__grid--email-txt {
      grid-row: 3;
      grid-column: 2/3; }

.forma {
  background-color: white;
  padding: 3em 2.3em;
  font-family: MontseRegular; }
  @media (min-width: 760px) {
    .forma {
      padding: 5em; } }
  @media (min-width: 1200px) {
    .forma {
      height: 550px;
      width: 500px;
      padding: 4em 4em;
      top: 50%;
      transform: translateY(-50%);
      right: 120px;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  @media (min-width: 1650px) {
    .forma {
      height: 550px;
      width: 550px;
      right: 200px; } }
  .forma__txt {
    color: #67131c; }
  .forma__form {
    margin: 1em 0; }
    @media (min-width: 1200px) {
      .forma__form {
        margin: 0; } }

.form__group {
  position: relative;
  margin-top: 20px; }

.form__label {
  position: absolute;
  left: 0;
  top: 10px;
  color: #4f4f4f;
  background-color: transparent;
  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out; }

.form__input {
  position: relative;
  padding: 12px 0px 5px 0;
  width: 100%;
  outline: 0;
  border: none;
  border-radius: 0px;
  border-bottom: 1px #67131c solid;
  transition: border-bottom 150ms ease-out; }
  .form__input:focus {
    border-bottom: 2px #1E2834 solid; }
  .form__input .filled {
    box-shadow: 0 2px 0 0 lightgreen; }

.form__submit {
  -webkit-appearance: none;
  border-radius: 0px;
  margin-top: 1.2em;
  width: 160px; }
  .form__submit:hover {
    cursor: pointer; }

.form__label.focused {
  transform: translateY(-125%);
  font-size: 0.75em; }

.forma.success {
  display: none; }

.success_msg {
  display: none;
  background-color: white;
  padding: 3em 2.3em;
  font-family: MontseRegular;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 200px; }
  @media (min-width: 760px) {
    .success_msg {
      padding: 5em; } }
  @media (min-width: 1200px) {
    .success_msg {
      height: 350px;
      width: 500px;
      padding: 4em 4em;
      top: 50%;
      transform: translateY(-50%);
      right: 120px;
      position: absolute; } }
  @media (min-width: 1650px) {
    .success_msg {
      right: 200px; } }
  .success_msg--txt {
    font-family: MontseRegular;
    color: #67131c;
    font-size: 23px; }

.success_msg.success {
  display: flex; }

.footer {
  font-family: MontseRegular;
  display: block;
  height: 50px;
  background-color: #67131c;
  width: 100%;
  color: #f5f5f5;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center; }
